document.addEventListener("DOMContentLoaded", function () {
  // nav
  const drawerButton = document.getElementById("js-list-nav-button");
  const drawerNav = document.getElementById("js-list-nav");
  const body = document.body;

  drawerButton.addEventListener("click", function () {
    drawerButton.classList.toggle("is-active");
    drawerNav.classList.toggle("is-active");
    body.classList.toggle("is-menu-active");
  });

  // scroll in
  const objects = document.querySelectorAll('.js-scroll-in');
  const cb = function (entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('is-show');
        observer.unobserve(entry.target);
      }
    });
  };

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0
  };

  const intersectionObserver = new IntersectionObserver(cb, options);

  objects.forEach(object => {
    intersectionObserver.observe(object);
  });
});
